function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Journal of Economic Boundaries And Transformation")]),_vm._v(" "),_c('p',[_vm._v("JEBT examines the changes of the countries of Eastern European region first in the context of economic limitations and troubles experienced while achieving dramatic transformation and resulting in successful growth.")]),_vm._v(" "),_c('p',[_vm._v("The aims of JEBT is to answer many questions concerning limitations, possibilities and suitable goals for transformation in all economic area. Research papers which investigate new horizons and paths to overcome the boundaries of the economic stereotypes.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/2738-7453","lang":"en","title":"Journal of Economic Boundaries And Transformation","description":"The journal examines the changes of the countries of Eastern European region first in the context of economic limitations and troubles experienced while achieving dramatic transformation and resulting in successful growth.","issn":"2738-7453","image":"/images/cover-2738-7453.jpg","order":1,"restricted":false},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }