function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Journal Subscription Request")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Jorunal: "+_vm._s(_vm.data.journal.name))]),_vm._v(" "),_c('li',[_vm._v("ISSN: "+_vm._s(_vm.data.journal.issn))])]),_vm._v(" "),_c('h2',[_vm._v("Contact details")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Name: "+_vm._s(_vm.data.profile.name))]),_vm._v(" "),_c('li',[_vm._v("Name: "+_vm._s(_vm.data.profile.email))]),_vm._v(" "),_c('li',[_vm._v("Name: "+_vm._s(_vm.data.profile.phone))])])])}
var staticRenderFns = []

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"subject":"Journal subscription request notification","to":"kamen.kanchev@gmail.com"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }