function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Journal Subscription Request")]),_vm._v(" "),_c('p',[_vm._v("Dear "+_vm._s(_vm.data.profile.name)+",")]),_vm._v(" "),_c('p',[_vm._v("Your subscription request has been received. A SciBulCom representative will contact you for further details.")]),_vm._v(" "),_c('h2',[_vm._v("Request Information")]),_vm._v(" "),_c('h3',[_vm._v("Journal Details")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Jorunal: "+_vm._s(_vm.data.journal.name))]),_vm._v(" "),_c('li',[_vm._v("ISSN: "+_vm._s(_vm.data.journal.issn))])]),_vm._v(" "),_c('h3',[_vm._v("Contact Details")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Name: "+_vm._s(_vm.data.profile.name))]),_vm._v(" "),_c('li',[_vm._v("Email: "+_vm._s(_vm.data.profile.email))]),_vm._v(" "),_c('li',[_vm._v("Phone: "+_vm._s(_vm.data.profile.phone))])]),_vm._v(" "),_c('p',[_vm._v("Sincerely Yours,\nThe team of SciBulCom")])])}
var staticRenderFns = []

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"subject":"Journal subscription request confirmation"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }