function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("About Journal of Economic Boundaries And Transformation")]),_vm._v(" "),_c('p',[_c('small',{staticClass:"text-muted"},[_vm._v("ISSN 2738-7453")])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("The Faculty of Economics and Business Administration (FEBA) at St. Kliment Ohridski University in Sofia is one of the leading institutions for teaching and research in the field of Economics and Business in Bulgaria according to research papers of experts of the World Bank. Now FEBA produces high quality experts in economics and business. For achieving this FEBA has developed into a center of economic thought in Bulgaria with increasing international research activities and thus contributing to the integration of research in Europe and beyond. FEBA is the publisher of the Journal of Economic Boundaries and Transformation - JEBT.")]),_vm._v(" "),_c('p',[_vm._v("JEBT examines the changes of the countries of Eastern European region first in the context of economic limitations and troubles experienced while achieving dramatic transformation and resulting in successful growth.\nThe aims of JEBT is to answer many questions concerning limitations, possibilities and suitable goals for transformation in all economic area. Research papers which investigate new horizons and paths to overcome the boundaries of the economic stereotypes.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/about","lang":"en","title":"About","header":"large"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }