function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("Editors")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h3',[_vm._v("Editor-in-chief")]),_vm._v(" "),_c('p',[_c('a',{attrs:{"href":"https://www.researchgate.net/profile/Todor_Yalamov"}},[_c('img',{attrs:{"src":"https://www.uni-sofia.bg/var/ezwebin_site/storage/images/media/images/todor_yalamov/299752-1-bul-BG/todor_yalamov.jpg","alt":"Assoc. Prof. Todor Yalamov, Ph.D."}}),_vm._v(" Todor Yalamov, Sofia University, Bulgaria")])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h4',[_vm._v("Editorial board")]),_vm._v(" "),_c('ul',[_c('li',[_vm._v("Colin Williams, University of Sheffield, United Kingdom")]),_vm._v(" "),_c('li',[_vm._v("Andreas Buehn, University of Cooperative Education Bautzen, Germany")]),_vm._v(" "),_c('li',[_vm._v("Kai-Ingo Voigt, Friedrich-Alexander-Universität Erlangen-Nürnberg, Germany")]),_vm._v(" "),_c('li',[_vm._v("Changqi Wu, Shandong University and Peking University, China")]),_vm._v(" "),_c('li',[_vm._v("Joachim Schwalbach, Humboldt University of Berlin, Germany")]),_vm._v(" "),_c('li',[_vm._v("Alberto Vannucci, University of Pisa, Italy")]),_vm._v(" "),_c('li',[_vm._v("Albena Vutsova, Sofia University, Bulgaria")]),_vm._v(" "),_c('li',[_vm._v("Bruno Boidin, University of Lille, France")]),_vm._v(" "),_c('li',[_vm._v("Gilles Rouet, Université de Versailles Saint-Quentin, France")]),_vm._v(" "),_c('li',[_vm._v("Soon-Thiam Khu, Tianjin University, China")]),_vm._v(" "),_c('li',[_vm._v("Tatiana Manolova, Bentley University, USA")]),_vm._v(" "),_c('li',[_vm._v("Aylin Alin, Dokuz Eylül University, Turkey")]),_vm._v(" "),_c('li',[_vm._v("Lykourgos Magafas, International Hellenic University, Greece")])])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/2738-7453/editors","lang":"en","title":"Editors"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }